<template>
  <v-container fluid class="user-select">
    <!-- <v-overlay class="loading-center" :value="loading">
      <lottie
        :options="defaultOptions"
   :height="150"
        :width="150"
        v-on:animCreated="handleAnimation"
      />
    </v-overlay> -->

    <v-row class="pa-0 ma-0">
      <v-col cols="12" md="8">
        <h1>Bitácora</h1>
      </v-col>

      <v-col cols="8" md="4">
        <v-select
          v-model="filterBy"
          :items="filterOptions"
          prepend-icon="fa-filter"
          label="Filtrar"
          item-text="text"
          item-value="value"
          hide-details
          filled
          rounded
          @change="getData"
        >
        </v-select>
      </v-col>
    </v-row>

    <v-row class="pa-0 ma-0">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="filteredLedgers"
          :items-per-page="10"
          :search="$store.state.search"
          :loading="loading"
          sort-by="createdAt"
          :sort-desc="true"
          :expanded.sync="expanded"
          show-expand
          :footer-props="{
            itemsPerPageOptions: [10, 25, 50, 100, 250],
          }"
        >
          <template v-slot:[`item.user_id`]="{ item }">
            {{ item.userInfo }}
          </template>

          <template v-slot:[`item.createdAt`]="{ item }">
            {{ item.createdAt | formatDate }}
          </template>

          <template v-slot:[`item.source_name`]="{ item }">
            {{ item.source_name || item.prevValue.shortName }}
          </template>

          <template v-slot:[`item.location`]="{ item }">
            {{
               `${item.location.region || ''} ${item.location.city || ''} ${item.location.country_code3 || ''} IP: ${item.location.ip}`
            }}
          </template>

          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <thead>
                <tr>
                  <th>Lugar</th>
                  <th>Acción</th>
                  <th>Valor Anterior</th>
                  <th>Valor Actual</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(diff, idx) in difference(
                    item.prevValue,
                    item.currentValue
                  )"
                  :key="idx"
                >
                  <td>
                    {{ diff.path | handlePath }}
                  </td>
                  <td>
                    {{ diff.op | translate }}
                  </td>
                  <td>
                    {{ diff.oldVal }}
                  </td>
                  <td>
                    {{ diff.val }}
                  </td>
                </tr>
              </tbody>
            </td>
          </template>
          <template v-slot:loading>
            <div class="ma-5" style="position: relative">
              <div
                style="
                  position: absolute;
                  z-index: 999;
                  width: 100%;
                  height: 100%;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                "
              >
                <lottie
                  :options="defaultOptions"
                  :height="300"
                  :width="400"
                  v-on:animCreated="handleAnimation"
                />
                <p
                  :class="
                    $vuetify.theme.dark
                      ? 'subtitle-1 primary--text'
                      : 'subtitle-1 black--text'
                  "
                >
                  Cargando Data
                </p>
              </div>
              <v-skeleton-loader
                ref="skeleton"
                type="table-tbody"
                class="mx-auto d-none d-md-block"
              ></v-skeleton-loader>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-row class="pa-0 ma-0" v-if="filteredLedgers.length == 0">
      <v-col cols="12" class="text-center">
        <p>No existen movimientos</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Lottie from "@/components/Lottie";
import lottieSettings from "@/mixins/lottieMixin";
import { db } from "@/firebase";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
const diff = require("recursive-diff");

export default {
  name: "ledger",
  components: {
    lottie: Lottie,
  },
  mixins: [lottieSettings],
  data() {
    return {
      loading: true,
      expanded: [],
      ledgers: [],
      filteredLedgers: [],
      users: [],
      headers: [
        {
          text: "Tabla",
          value: "source_type",
        },
        {
          text: "Id",
          value: "source_id",
        },
        {
          text: "Nombre del recurso",
          value: "source_name",
          width : '300px'
        },
        {
          text: "Usuario",
          value: "user_id",
          width: "200px",
        },
        {
          text: "Fecha",
          value: "createdAt",
          width: "200px",
        },
        {
          text: "Ubicación",
          value: "location",
        },
      ],
      filterBy: { text: "Todos", value: "all" },
      filterOptions: [
        { text: "Todos", value: "all" },
        { text: "Comercio", value: "businesses" },
        { text: "Productos", value: "products" },
        { text: "Cupones", value: "coupons" },
        { text: "Clientes", value: "customers" },
        { text: "Menu", value: "menu" },
      ],
    };
  },
  async mounted() {
    this.$store.commit("setSearchTerm", "bitácora");
    this.$store.commit("setSearch", "");
    this.$store.state.visibleSearch = true;
    await this.$binding("users", db.collection("appUsers"));
    await this.getLedger();
  },
  methods: {
    async getLedger() {
      db.collection("ledgers")
        .limit(300)
        .orderBy("createdAt", "desc")
        .onSnapshot(async (querySnapshot) => {
          let tempArray = [];

          querySnapshot.forEach((doc) => {
            tempArray.push(Object.assign({ id: doc.id }, doc.data()));
          });

          this.ledgers = tempArray;
          this.filteredLedgers = this.ledgers;
          await this.assignUserNames();
          this.loading = false;
        });
    },
    async assignUserNames() {
      this.ledgers.forEach((item) => {
        let result = this.users.find((user) => user[".key"] == item.user_id);
        item.userInfo = result.name;
      });
      // for (let i = 0; i < this.ledgers.length; i++) {
      //   let user = await db
      //     .collection("appUsers")
      //     .doc(this.ledgers[i].user_id)
      //     .get();

      //   this.ledgers[i].userInfo = user.data().name;
      // }
    },
    difference(prev, current) {
      return diff.getDiff(prev, current, true);
    },
    getData() {
      if (this.filterBy == "all") {
        this.filteredLedgers = this.ledgers;
      } else {
        this.filteredLedgers = this.ledgers.filter((ledger) => {
          return ledger["source_type"] == this.filterBy;
        });
      }
    },
  },
  filters: {
    formatDate(timestamp) {
      if (!timestamp) return "no disponible";
      return moment(timestamp.toDate()).tz("America/Tegucigalpa").format("LL");
    },
    translate(word) {
      if (word == undefined) return;
      switch (word) {
        case "add":
          return "agregado";
        case "delete":
          return "borrado";
        default:
          return "actualizado";
      }
    },
    handlePath(path) {
      if (path == undefined) return "";
      if (Array.isArray(path)) return path.join("/");
    },
  },
};
</script>

<style scoped>
.user-select {
  user-select: text;
}
.inner-lottie {
  position: absolute;
  z-index: 999;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
