var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"user-select",attrs:{"fluid":""}},[_c('v-row',{staticClass:"pa-0 ma-0"},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('h1',[_vm._v("Bitácora")])]),_c('v-col',{attrs:{"cols":"8","md":"4"}},[_c('v-select',{attrs:{"items":_vm.filterOptions,"prepend-icon":"fa-filter","label":"Filtrar","item-text":"text","item-value":"value","hide-details":"","filled":"","rounded":""},on:{"change":_vm.getData},model:{value:(_vm.filterBy),callback:function ($$v) {_vm.filterBy=$$v},expression:"filterBy"}})],1)],1),_c('v-row',{staticClass:"pa-0 ma-0"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filteredLedgers,"items-per-page":10,"search":_vm.$store.state.search,"loading":_vm.loading,"sort-by":"createdAt","sort-desc":true,"expanded":_vm.expanded,"show-expand":"","footer-props":{
          itemsPerPageOptions: [10, 25, 50, 100, 250],
        }},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:`item.user_id`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.userInfo)+" ")]}},{key:`item.createdAt`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.createdAt))+" ")]}},{key:`item.source_name`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.source_name || item.prevValue.shortName)+" ")]}},{key:`item.location`,fn:function({ item }){return [_vm._v(" "+_vm._s(`${item.location.region || ''} ${item.location.city || ''} ${item.location.country_code3 || ''} IP: ${item.location.ip}`)+" ")]}},{key:"expanded-item",fn:function({ headers, item }){return [_c('td',{attrs:{"colspan":headers.length}},[_c('thead',[_c('tr',[_c('th',[_vm._v("Lugar")]),_c('th',[_vm._v("Acción")]),_c('th',[_vm._v("Valor Anterior")]),_c('th',[_vm._v("Valor Actual")])])]),_c('tbody',_vm._l((_vm.difference(
                  item.prevValue,
                  item.currentValue
                )),function(diff,idx){return _c('tr',{key:idx},[_c('td',[_vm._v(" "+_vm._s(_vm._f("handlePath")(diff.path))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("translate")(diff.op))+" ")]),_c('td',[_vm._v(" "+_vm._s(diff.oldVal)+" ")]),_c('td',[_vm._v(" "+_vm._s(diff.val)+" ")])])}),0)])]}},{key:"loading",fn:function(){return [_c('div',{staticClass:"ma-5",staticStyle:{"position":"relative"}},[_c('div',{staticStyle:{"position":"absolute","z-index":"999","width":"100%","height":"100%","display":"flex","flex-direction":"column","justify-content":"center","align-items":"center"}},[_c('lottie',{attrs:{"options":_vm.defaultOptions,"height":300,"width":400},on:{"animCreated":_vm.handleAnimation}}),_c('p',{class:_vm.$vuetify.theme.dark
                    ? 'subtitle-1 primary--text'
                    : 'subtitle-1 black--text'},[_vm._v(" Cargando Data ")])],1),_c('v-skeleton-loader',{ref:"skeleton",staticClass:"mx-auto d-none d-md-block",attrs:{"type":"table-tbody"}})],1)]},proxy:true}],null,true)})],1)],1),(_vm.filteredLedgers.length == 0)?_c('v-row',{staticClass:"pa-0 ma-0"},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('p',[_vm._v("No existen movimientos")])])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }